/* eslint-disable guard-for-in */
<template>
  <v-dialog no-click-animation v-model="value" persistent fullscreen hide-overlay transition="dialog-bottom-transition" eager>
    <v-card tile>
      <the-nav-bar title="Dados Dependente" @back="close"></the-nav-bar>
      <v-container class="pa-5 pt-0">
        <validation-observer ref="form" tag="form" @submit.prevent="save">
          <v-row justify="center" class="div_fields">
            <v-col class="col-12 col-md-6 pb-0">
              <base-text-field
                value="teste"
                :data-test-id="'dob-dependente'"
                rules="required|date"
                id="dataNascimento"
                name="Data de nascimento"
                label="Data de nascimento *"
                placeholder="DD/MM/AAAA"
                v-mask="'##/##/####'"
                inputmode="numeric"
                clearable
                @input="inputIdade"
                v-model="dataNascimento"
                :disabled="desabilitaInput"
                mode="aggressive"
                outlined
              ></base-text-field>
              <async-cpf :cpf="form.cpf" :dataNascimento="form.dataNascimento" :loadingMounted="loadingMountedCpf" rotina="Consultar CPF do dependente da proposta"
                v-slot="{ loading: loadingCpf }" @success="successCpf($event)" @error="loadingMountedCpf = false">
                <base-text-field
                  value
                  rules="required|cpf"
                  id="cpf"
                  :data-test-id="'cpf-dependente'"
                  name="CPF"
                  label="CPF *"
                  v-mask="'###.###.###-##'"
                  inputmode="numeric"
                  v-model="form.cpf"
                  :loading="loadingCpf"
                  clearable
                  :disabled="desabilitaInput || disableCPF"
                  outlined
                >
                </base-text-field>
              </async-cpf>
              <base-text-field
                mode="aggressive"
                rules="required|special_characters"
                id="nome"
                :data-test-id="'nome-dependente'"
                name="Nome completo"
                value
                label="Nome completo *"
                v-model="form.nome"
                :disabled="desabilitaInput"
                clearable
                outlined
              ></base-text-field>
              <base-text-field
                mode="aggressive"
                rules="required|special_characters"
                id="nomeMae"
                :data-test-id="'nome-mae-dependente'"
                name="Nome da mãe"
                value
                label="Nome da mãe *"
                v-model="form.nomeMae"
                :disabled="desabilitaInput"
                clearable
                outlined
              ></base-text-field>
              <base-wrapper-dialog-options
                headerTitle="Sexo"
                :optionSelected="form.sexo"
                :options="[
                  { valor: 'm', descricao: 'Masculino' },
                  { valor: 'f', descricao: 'Feminino' },
                ]"
                @select="$set(form, 'sexo', $event.descricao)"
                value-key="valor"
                label-key="descricao"
                ref="dialogSexo"
              >
                <template v-slot:activador="{ on }">
                  <base-text-field
                    readonly
                    rules="required"
                    placeholder="Clique aqui para selecionar"
                    id="sexo"
                    :data-test-id="'sexo-dependente'"
                    name="Sexo"
                    v-on="on"
                    :value="form.sexo"
                    label="Sexo *"
                    outlined
                    append-icon="mdi-chevron-right"
                    :disabled="desabilitaInput"
                  ></base-text-field>
                </template>
              </base-wrapper-dialog-options>
              <async-list-grau-parentesco v-slot="{ loading: loadingParentesco }" @success="setGrauParentesco">
                <base-wrapper-dialog-options
                  v-if="!loadingParentesco"
                  :optionSelected="form.parentesco"
                  headerTitle="Grau parentesco"
                  :options="parentescosFiltrado"
                  @select="selectedGrauParentesco($event)"
                  value-key="parentesco"
                  label-key="parentesco"
                  :loading="loadingParentesco"
                >
                  <template v-slot:activador="{ on }">
                    <base-text-field
                      rules="required"
                      readonly
                      id="parentesco"
                      :data-test-id="'parentesco-dependente'"
                      placeholder="Clique aqui para selecionar"
                      name="Grau parentesco"
                      v-on="on"
                      :value="form.parentesco"
                      label="Grau parentesco *"
                      outlined
                      append-icon="mdi-chevron-right"
                    >
                    </base-text-field>
                  </template>
                </base-wrapper-dialog-options>
                <base-text-field
                  v-if="dataEventoObrigatorio"
                  rules="required|date"
                  id="dataEvento"
                  :data-test-id="'data-evento'"
                  name="Data do evento"
                  value
                  label="Data do evento *"
                  v-model="form.dataEvento"
                  v-mask="'##/##/####'"
                  :disabled="verificaGrauParentescoFilho"
                  clearable
                  outlined
                  hide-details
                  class="mb-2"
                >
                  <v-tooltip top right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-information</v-icon>
                    </template>
                    <span class="caption" style="line-height: 1"
                      >Informe a data do evento de acordo com o grau de parentesco como por exemplo: data do nascimento, casamento, união estável, entre outros.</span
                    >
                  </v-tooltip>
                </base-text-field>
                <div v-if="dataEventoObrigatorio" class="custom-hint mb-5">
                  Informe a data do evento de acordo com o grau de parentesco como por exemplo: data do nascimento, casamento, união estável, entre outros.
                </div>
                <base-wrapper-dialog-options
                  headerTitle="Estado civil"
                  :optionSelected="form.estadoCivil"
                  :options="estadosCivis"
                  @select="$set(form, 'estadoCivil', $event.nome)"
                  ref="dialogEstadoCivil"
                  value-key="id"
                  label-key="nome"
                >
                  <template v-slot:activador="{ on }">
                    <base-text-field
                      :loading="loadingParentesco"
                      rules="required"
                      mode="lazy"
                      readonly
                      id="estadoCivil"
                      :data-test-id="'estado-civil-dependente'"
                      placeholder="Clique aqui para selecionar"
                      name="Estado civil"
                      v-on="on"
                      :value="form.estadoCivil"
                      label="Estado civil *"
                      outlined
                      append-icon="mdi-chevron-right"
                      :disabled="form.parentesco ? false : true"
                    ></base-text-field>
                  </template>
                </base-wrapper-dialog-options>
              </async-list-grau-parentesco>

              <!-- CAMPO EXCLUSIVO UNIMED POA -->

              <div v-if="propostaState.dpsPorBeneficiario && idade >= 18">
              <base-text-field
                type="tel"
                mode="eager"
                :rules="idade >= 18 && propostaState.dpsPorBeneficiario ? 'required|cellphone|ddd' : 'cellphone|ddd'"
                id="celular"
                name="Telefone celular"
                :data-test-id="'informar-telefone'"
                v-model="form.celular"
                :label="`Telefone Celular ${idade >= 18 && propostaState.dpsPorBeneficiario ? '*' : ''}`"
                v-mask="'## #####-####'"
                :disabled="desabilitaInput"
                clearable
                outlined
                hint="Mesmo número que haverá confirmação via sms para conclusão da proposta"
                persistent-hint
                class="mb-3"
              >
                <v-tooltip top right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information</v-icon>
                   </template>
                  <span class="caption" style="line-height: 1">Mesmo número que haverá confirmação via sms para assinatura da DPS</span>
                </v-tooltip>
             </base-text-field>

             <base-text-field
                type="email"
                :data-test-id="'valida-email'"
                mode="eager"
                :rules="idade >= 18 && propostaState.dpsPorBeneficiario ? 'required|email' : 'email'"
                id="email"
                name="E-mail"
                v-model="form.email"
                :label="`E-mail ${idade >= 18 && propostaState.dpsPorBeneficiario ? '*' : ''}`"
                clearable
                outlined
              ></base-text-field>
              </div>
              <div v-if="propostaState.plano.operadora === 'Smile Saúde' && dnvObrigatorio && verificaGrauParentesco">
              <base-text-field
                :rules="verificaRgVazio && dnvObrigatorio ? 'required' : ''"
                id="dnvDependente"
                :data-test-id="'dnv-dependente'"
                name="Declaração Nascido Vivo"
                :label="`Declaração Nascido Vivo ${verificaRgVazio && dnvObrigatorio ? '*' : ''}`"
                v-model="form.declaracaoNascidoVivo"
                maxlength="12"
                v-mask="'##-########-#'"
                inputmode="numeric"
                clearable
                outlined
              ></base-text-field>
              </div>
              <div v-else-if="dnvObrigatorio">
              <base-text-field
                :rules="dnvObrigatorio ? 'required' : ''"
                id="dnvDependente"
                :data-test-id="'dnv-dependente'"
                name="Declaração Nascido Vivo"
                :label="`Declaração Nascido Vivo ${dnvObrigatorio ? '*' : ''}`"
                v-model="form.declaracaoNascidoVivo"
                maxlength="12"
                v-mask="'##-########-#'"
                inputmode="numeric"
                clearable
                outlined
              ></base-text-field>
              </div>
              <base-text-field
                :rules="verificaDnvVazio && rgObrigatorio ? 'required' : ''"
                id="identidade"
                :data-test-id="'identidade-dependente'"
                name="Identidade"
                v-model="form.rg"
                :label="`Identidade ${verificaDnvVazio && rgObrigatorio ? '*' : ''}`"
                maxlength="20"
                clearable
                outlined
              ></base-text-field>
              <div v-if="emissorObrigatorio && propostaState.administradora !== 'plural'">
              <base-text-field
                :rules="emissorObrigatorio ? 'required' : ''"
                id="rgOrgaoEmissor"
                :data-test-id="'orgao-emissor-dependente'"
                name="Orgão Emissor"
                :label="`Orgão Emissor ${emissorObrigatorio ? '*' : ''}`"
                v-model="form.rgOrgaoEmissor"
                v-mask="'AAAAAAAAA'"
                clearable
                outlined
              ></base-text-field>
              </div>
              <div v-if="emissorObrigatorio && propostaState.administradora === 'plural'">
                <base-wrapper-dialog-options headerTitle="Orgão Emissor" :options="listOrgaoEmissor" @select="$set(form, 'rgOrgaoEmissor', $event.id)" value-key="id" label-key="nome">
                  <template v-slot:activador="{ on }">
                    <base-text-field
                      :rules="emissorObrigatorio ? 'required' : ''"
                      placeholder="Clique aqui para selecionar"
                      id="rgOrgaoEmissor"
                      name="Orgão Emissor"
                      v-on="on"
                      :value="form.rgOrgaoEmissor"
                      :label="`Orgão Emissor ${emissorObrigatorio ? '*' : ''}`"
                      outlined
                      append-icon="mdi-chevron-right"
                    ></base-text-field>
                  </template>
                </base-wrapper-dialog-options>
              </div>
              <div v-if="ufObrigatorio">
              <base-wrapper-dialog-options headerTitle="UF Orgão Emissor" :options="listUf" @select="$set(form, 'rgOrgaoEmissorUf', $event.id)" value-key="id" label-key="nome">
                <template v-slot:activador="{ on }">
                  <base-text-field
                    :rules="ufObrigatorio ? 'required' : ''"
                    placeholder="Clique aqui para selecionar"
                    id="rgOrgaoEmissorUf"
                    :data-test-id="'uf-emissor-dependente'"
                    name="UF Orgão Emissor"
                    v-on="on"
                    :value="form.rgOrgaoEmissorUf"
                    :label="`UF Orgão Emissor ${ufObrigatorio ? '*' : ''}`"
                    outlined
                    append-icon="mdi-chevron-right"
                  ></base-text-field>
                </template>
              </base-wrapper-dialog-options>
              </div>
              <div v-if="dataExpedicaoObrigatorio">
              <base-text-field
                :rules="dataExpedicaoObrigatorio ? 'required' : ''"
                id="rgDataExpedicao"
                :data-test-id="'data-expedicao-dependente'"
                name="Data de Expedição"
                :label="`Data de Expedição ${dataExpedicaoObrigatorio ? '*' : ''}`"
                v-model="form.rgDataExpedicao"
                v-mask="'##/##/####'"
                clearable
                outlined
              ></base-text-field>
              </div>

              <div v-if="cnsObrigatorio">
                <base-text-field
                  :rules="cnsObrigatorio ? 'required|cns' : ''"
                  id="numeroCns"
                  name="Cartão Nacional Saúde"
                  :data-test-id="'numero-cns'"
                  :label="`Cartão Nacional Saúde ${cnsObrigatorio ? '*' : ''}`"
                  v-model="form.cns"
                  v-mask="'###.####.####.####'"
                  inputmode="numeric"
                  clearable
                  outlined
                ></base-text-field>
              </div>

              <base-text-field
                rules="pisPasep"
                id="pisPasep"
                :data-test-id="'pis-pasep-dependente'"
                name="PIS/PASEP"
                v-model="form.pisPasep"
                label="PIS/PASEP"
                v-mask="'###.#####.##-#'"
                inputmode="numeric"
                clearable
                outlined
              ></base-text-field>
              <base-wrapper-dialog-options
                headerTitle="Redução de carência"
                :options="[
                  { id: 'Sim', nome: 'Sim', value: true },
                  { id: 'Não', nome: 'Não', value: false },
                ]"
                @select="$set(reducaoCarencia, 'temPlano', $event.id)"
                value-key="value"
                label-key="nome"
                ref="dialogtemPlano"
                v-if="exibirReducaoCarencia"
              >
                <template v-slot:activador="{ on }">
                  <base-text-field
                    readonly
                    v-on="on"
                    rules="required"
                    id="temPlano"
                    :data-test-id="'reducao-carencia-dependente'"
                    name="temPlano"
                    :value="reducaoCarencia.temPlano"
                    v-model="reducaoCarencia.temPlano"
                    placeholder="Clique aqui para selecionar"
                    label="Vai anexar documento que comprove redução de carência? *"
                    outlined
                    append-icon="mdi-chevron-right"
                  ></base-text-field>
                </template>
              </base-wrapper-dialog-options>
              <div v-if="propostaState.plano.operadora.toLowerCase() === 'unimed volta redonda'">
                <base-text-field
                  inputmode="text"
                  :rules="reducaoCarencia.temPlano === 'Sim' ? 'required' : ''"
                  id="operadoraAnterior"
                  name="Operadora Anterior"
                  v-model="form.operadoraAnterior"
                  :label="`Operadora Anterior ${reducaoCarencia.temPlano === 'Sim' ? '*' : ''}`"
                  clearable
                  outlined
                ></base-text-field>
                <base-text-field
                  inputmode="numeric"
                  :rules="form.temPlano === 'Sim' ? 'required' : ''"
                  id="tempoPlanoEmMesesAnterior"
                  name="Tempo em meses do plano Anterior"
                  v-model="form.tempoPlanoEmMesesAnterior"
                  :label="`Tempo em meses do plano Anterior ${reducaoCarencia.temPlano === 'Sim' ? '*' : ''}`"
                  clearable
                  outlined
                  v-mask="'###'"
                ></base-text-field>
                <base-wrapper-dialog-options
                  headerTitle="Redução de carência"
                  :options="[
                    { id: 'Coletiva', nome: 'Coletiva', value: 'coletiva' },
                    { id: 'Individual', nome: 'Individual', value: 'individual' },
                  ]"
                  @select="$set(form, 'acomodacaoAnterior', $event.id)"
                  value-key="value"
                  label-key="nome"
                  ref="dialogacomodacaoAnterior"
                >
                  <template v-slot:activador="{ on }">
                    <base-text-field
                      readonly
                      v-on="on"
                      :rules="reducaoCarencia.temPlano === 'Sim' ? 'required' : ''"
                      id="acomodacaoAnterior"
                      name="acomodacaoAnterior"
                      :value="form.acomodacaoAnterior"
                      v-model="form.acomodacaoAnterior"
                      placeholder="Clique aqui para selecionar"
                      :label="`Selecione a acomodação do plano anterior ${reducaoCarencia.temPlano === 'Sim' ? '*' : ''}`"
                      outlined
                      append-icon="mdi-chevron-right"
                    ></base-text-field>
                  </template>
                </base-wrapper-dialog-options>
              </div>
              <div v-if="propostaState.plano.operadora.toLowerCase() === 'unimed bh' && reducaoCarencia.temPlano === 'Sim'">
                <base-wrapper-dialog-options
                  headerTitle="Informe se a operadora anterior era Unimed BH"
                  :options="[
                              { id: 'Sim', nome: 'Sim', value: 'Sim' },
                              { id: 'Não', nome: 'Não', value: 'Não' },
                            ]"
                  @select="$set(form, 'confirmarOperadoraAnterior', $event.id)"
                  value-key="value"
                  label-key="nome"
                  ref="dialogAcomodacao"
                >
                  <template v-slot:activador="{ on }">
                    <base-text-field
                      readonly
                      clearable
                      v-on="on"
                      :rules="reducaoCarencia.temPlano === 'Sim' ? 'required' : ''"
                      id="confirmarOperadoraAnterior"
                      name="Informe se a operadora anterior era Unimed BH"
                      :value="form.confirmarOperadoraAnterior"
                      v-model="form.confirmarOperadoraAnterior"
                      placeholder="Clique aqui para selecionar"
                      :label="`Informe se a operadora anterior era Unimed BH ${reducaoCarencia.temPlano === 'Sim' ? '*' : ''}`"
                      outlined
                      append-icon="mdi-chevron-right"
                    ></base-text-field>
                  </template>
                </base-wrapper-dialog-options>
                <div v-if="form.confirmarOperadoraAnterior === 'Sim'">
                  <base-text-field
                    inputmode="numeric"
                    :rules="form.confirmarOperadoraAnterior === 'Sim' ? 'required' : ''"
                    id="campoCarteirinha"
                    name="Número Carteirinha"
                    v-model="form.campoCarteirinha"
                    v-mask="'###################'"
                    :label="`Informe o número da carteirinha do plano anterior  ${form.confirmarOperadoraAnterior === 'Sim' ? '*' : ''}`"
                    clearable
                    outlined
                  ></base-text-field>
                </div>
              </div>
              <div v-if="propostaState.plano.operadora.toLowerCase() === 'unimed-rio' && reducaoCarencia.temPlano !== null">
                <base-wrapper-dialog-options
                  headerTitle="Possuí plano anterior ? *"
                  :options="[
                    { id: 'Sim', nome: 'Sim', value: 'Sim' },
                    { id: 'Não', nome: 'Não', value: 'Não' },
                  ]"
                  @select="$set(form, 'possuiPlanoAnteriorReducaoCarencia', $event.id)"
                  value-key="value"
                  label-key="nome"
                  ref="dialogAcomodacao"
                >
                  <template v-slot:activador="{ on }">
                    <base-text-field
                      readonly
                      clearable
                      v-on="on"
                      rules="required"
                      id="possuiPlanoAnteriorReducaoCarencia"
                      name="Possuí plano anterior ?"
                      :value="form.possuiPlanoAnteriorReducaoCarencia"
                      v-model="form.possuiPlanoAnteriorReducaoCarencia"
                      placeholder="Clique aqui para selecionar"
                      label="Possuí plano anterior ? *"
                      outlined
                      append-icon="mdi-chevron-right"
                    ></base-text-field>
                  </template>
                </base-wrapper-dialog-options>
                <base-text-field
                  inputmode="numeric"                            
                  :rules="form.possuiPlanoAnteriorReducaoCarencia === 'Sim' ? 'required' : ''"  
                  v-mask="['###']"                          
                  id="tempoPlanoAnteriorReducaoCarencia"
                  name="Qual o tempo do plano anterior? Informe os meses"
                  v-model="form.tempoPlanoAnteriorReducaoCarencia"
                  :label="`Qual o tempo do plano anterior? Informe os meses ${form.possuiPlanoAnteriorReducaoCarencia === 'Sim' ? '*' : ''}`"
                  clearable
                  outlined
                ></base-text-field>
                <base-text-field
                  inputmode="text"                            
                  :rules="form.possuiPlanoAnteriorReducaoCarencia === 'Sim' ? 'required' : ''"                              
                  id="operadoraPlanoAnteriorReducaoCarencia"
                  name="Qual Operadora do plano anterior?"
                  v-model="form.operadoraPlanoAnteriorReducaoCarencia"
                  :label="`Qual Operadora do plano anterior? ${form.possuiPlanoAnteriorReducaoCarencia === 'Sim' ? '*' : ''}`"
                  clearable
                  outlined
                ></base-text-field>
                <base-text-field
                  inputmode="text"                            
                  :rules="form.possuiPlanoAnteriorReducaoCarencia === 'Sim' ? 'required' : ''"                              
                  id="registroAnsPlanoAnteriorReducaoCarencia"
                  name="Qual Registro ANS do plano anterior?"
                  v-model="form.registroAnsPlanoAnteriorReducaoCarencia"
                  :label="`Qual Registro ANS do plano anterior? ${form.possuiPlanoAnteriorReducaoCarencia === 'Sim' ? '*' : ''}`"
                  clearable
                  outlined
                ></base-text-field>
              </div>

            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col class="col-12 col-md-6">
              <v-btn :data-test-id="'salvar'" large type="submit" elevation="10" block color="primary" class="secundaryBtn--text mt-4" :loading="loading">Salvar</v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.div_fields {
  margin-top: 3%;
}
</style>

<script>
import { mapState } from "vuex";
import { getPlanoSimilar } from "@/mixins/getPlanoSimilar";
import TheNavBar from "@/components/TheNavBar.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";
import AsyncListGrauParentesco from "@/components/AsyncListGrauParentesco.vue";
import AsyncCpf from "@/components/AsyncCpf.vue";
import regrasService from "@/services/regrasService";
import ufList from "@/utils/constants/ufList";
import orgaoEmissorList from "@/utils/constants/orgaoEmissorList";
import moment from "moment";
import filters from "@/filters";
import validaService from '@/services/validaService';
import listaAtencaoService from "@/services/listaAtencaoService";
import { find } from "lodash";

export default {
  name: "DialogDependente",
  components: {
    TheNavBar,
    BaseTextField,
    BaseWrapperDialogOptions,
    AsyncListGrauParentesco,
    AsyncCpf,
  },
  props: {
    dependente: {
      required: true,
    },
    value: {
      default: false,
    },
  },
  mixins: [getPlanoSimilar],
  data() {
    return {
      form: {
        possuiPlano: "",
        //UNIMED RIO
        possuiPlanoAnteriorReducaoCarencia: "",
        tempoPlanoAnteriorReducaoCarencia: "",
        registroAnsPlanoAnteriorReducaoCarencia: "",
        operadoraPlanoAnteriorReducaoCarencia: "",
        //UNIMED RIO
      },
      reducaoCarencia: {
        temPlano: null,
      },
      anoNascimento: null,
      idade: null,
      listUf: ufList,
      listOrgaoEmissor: orgaoEmissorList,
      obrigatorio: true,
      nomeMaeError: null,
      dataNascimento: null,
      parentescos: [],
      parentescosFiltrado: [],
      estadosCivis: [],
      loadingMountedCpf: false,
      loading: false,
      obrigatoriedades: [],
      rgObrigatorio: false,
      dnvObrigatorio: false,
      emissorObrigatorio: false,
      ufObrigatorio: false,
      dataExpedicaoObrigatorio: false,
      cnsObrigatorio: false,
      dataEventoObrigatorio: false,
      disableCPF: false,
    };
  },
  watch: {
    dependente: {
      handler() {
        const dependente = JSON.parse(JSON.stringify((this.dependente && this.dependente.dependente) || {}));
        dependente.estadoCivil = !dependente.grauParentesco ? '' : dependente.estadoCivil;
        this.form = {
          ...dependente,
          dataEvento: dependente.dataEvento ? moment(dependente.dataEvento).format("DD/MM/YYYY") : "",
        };
        this.setdataNascimento();
        this.idade = filters.getAge(new Date(this.form.dataNascimento));
        if (dependente.possuiPlano === true) {
          this.reducaoCarencia.temPlano = "Sim";
        } else if (dependente.possuiPlano === false) {
          this.reducaoCarencia.temPlano = "Não";
        }
        if (dependente.campoCarteirinha) {
          this.form.confirmarOperadoraAnterior = "Sim";
          this.reducaoCarencia.temPlano = "Sim";
        }
        this.form.rgDataExpedicao = dependente.rgDataExpedicao ? moment(dependente.rgDataExpedicao, "YYYY-MM-DD").format("DD/MM/YYYY") : "";
      },
      deep: true,
      immediate: true,
    },
    // eslint-disable-next-line func-names
    "form.parentesco": function (newVal) {
      if (newVal === 'FILHO') this.form.dataEvento = moment(this.form.dataNascimento).format("DD/MM/YYYY");
    },
  },
  mounted() {
    if (this.form.cpf) this.loadingMountedCpf = true;
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    desabilitaInput() {
      return (this.propostaState.status || "") !== "EM_DIGITACAO";
    },
    verificaGrauParentesco() {
      return (this.form.parentesco || "").toUpperCase() === "FILHO" || (this.form.parentesco || "").toUpperCase() === "IRMÃO";
    },
    verificaGrauParentescoFilho() {
      return (this.form.parentesco || "").toUpperCase() === "FILHO";
    },
    verificaDnvVazio() {
      return !this.form.declaracaoNascidoVivo;
    },
    verificaRgVazio() {
      return !this.form.rg;
    },
    exibirReducaoCarencia() {
      const operadoras = process.env.VUE_APP_ISENCAO_REDUCAO_CARENCIA_OPERADORAS.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").split(",");
      const operodaraDoState = this.propostaState.plano.operadora.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      let exibir = true;
      for (let index = 0, l = operadoras.length; index < l; index++) {
        const item = operadoras[index];
        if (item === operodaraDoState) {
          exibir = false;
          break;
        }
      }
      return exibir;
    },
  },
  async created() {
    await this.getIdade();
    await this.buscaRegra();
  },
  methods: {
    inputIdade(value, valido) {
      this.disableCPF = !valido;
      if (this.disableCPF) {
        this.form.cpf = '';
      }
      if (valido) {
        this.getIdade(value);
      }
    },
    async buscaRegra() {
      const data = await regrasService.buscaRegraRg({ operadora: this.propostaState.plano.operadora, idade: this.idade, anoNascimento: this.anoNascimento });
      this.obrigatoriedades = data.data;
      if (this.obrigatoriedades && this.obrigatoriedades.length) {
        const rg = this.obrigatoriedades.find((e) => e.campo === "RG");
        this.rgObrigatorio = rg ? rg.obrigatorio : false;
        const dnv = this.obrigatoriedades.find((e) => e.campo === "DNV");
        this.dnvObrigatorio = dnv ? dnv.obrigatorio : false;
        const orgaoEmissor = this.obrigatoriedades.find((e) => e.campo === "Orgao Emissor");
        this.emissorObrigatorio = orgaoEmissor ? orgaoEmissor.obrigatorio : false;
        const uf = this.obrigatoriedades.find((e) => e.campo === "UF");
        this.ufObrigatorio = uf ? uf.obrigatorio : false;
        const dataExpedicao = this.obrigatoriedades.find((e) => e.campo === "Data Expedicao");
        this.dataExpedicaoObrigatorio = dataExpedicao ? dataExpedicao.obrigatorio : false;
        const cns = this.obrigatoriedades.find((e) => e.campo === "CNS");
        this.cnsObrigatorio = cns ? cns.obrigatorio : false;
        const dataEvento = this.obrigatoriedades.find((e) => e.campo === "Data Evento");
        this.dataEventoObrigatorio = dataEvento ? dataEvento.obrigatorio : false;
      } else {
        this.rgObrigatorio = false;
        this.emissorObrigatorio = false;
        this.ufObrigatorio = false;
        this.dataExpedicaoObrigatorio = false;
        this.cnsObrigatorio = false;
        this.dnvObrigatorio = false;
        this.dataEventoObrigatorio = false;
      }
      return data;
    },
    async save() {
      try {
        const isValid = await this.$refs.form.validate();

        if (!isValid) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Verifique o preenchimento do formulário e tente novamente",
          });
          return;
        }

        if (this.form.declaracaoNascidoVivo) {
          const pattern = "0123456789012345678910";
          if (pattern.indexOf(filters.removeSymbols(this.form.declaracaoNascidoVivo)) !== -1) {
            this.$root.$snackBar.open({
              color: "error",
              message: "DNV não pode conter números sequenciais.",
            });
            return;
          }
          if (filters.removeSymbols(this.form.declaracaoNascidoVivo).length !== 11) {
            this.$root.$snackBar.open({
              color: "error",
              message: "Preencha o DNV com todos os números corretamente.",
            });
            return;
          }
          if (filters.removeSymbols(this.form.declaracaoNascidoVivo).split('').every((char) => char === this.form.declaracaoNascidoVivo[0])) {
            this.$root.$snackBar.open({
              color: "error",
              message: "O DNV não pode possuir números iguais.",
            });
            return;
          }
        }

        if (this.form.rg && validaService.validaRG(this.form.rg)) {
          this.$root.$snackBar.open({
            color: "error",
            message: "O RG não pode conter caracteres especiais.",
          });
          return;
        }

        this.loading = true;
        this.form.aceiteNaoReducaoCarencia = false;
        const payload = this.$cleanObserver(this.form);
        payload.check = true;
        payload.cpf = filters.removeSymbols(payload.cpf);
        if (payload.rgDataExpedicao === "Invalid date") {
          payload.rgDataExpedicao = "";
        }
        if (payload.rgDataExpedicao) {
          payload.rgDataExpedicao = moment(payload.rgDataExpedicao, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        payload.pisPasep = filters.removeSymbols(payload.pisPasep);
        payload.celular = filters.removeSymbols(payload.celular);
        payload.possuiPlano = this.reducaoCarencia.temPlano === "Sim";
        if (this.dnvObrigatorio) {
          payload.declaracaoNascidoVivo = filters.removeSymbols(payload.declaracaoNascidoVivo);
        } else {
          payload.declaracaoNascidoVivo = "";
        }
        if (this.reducaoCarencia.temPlano === "Não") {
          payload.campoCarteirinha = "";
        }
        if (payload.acomodacaoAnterior) {
          payload.acomodacaoAnterior = payload.acomodacaoAnterior.toLowerCase();
        }
        if (payload.tempoPlanoEmMesesAnterior) {
          payload.tempoPlanoEmMesesAnterior = parseInt(payload.tempoPlanoEmMesesAnterior, 10);
        }
        if (this.dataEventoObrigatorio && payload.dataEvento) {
          payload.dataEvento = moment(payload.dataEvento, "DD/MM/YYYY").format("YYYY-MM-DD");
        } else {
          payload.dataEvento = "";
        }
        const operadora = this.propostaState.plano.operadora;
        if ((operadora === 'SULAMÉRICA SAÚDE' || operadora === 'BLUE' || operadora === 'AMIL SAÚDE SA') && !this.propostaState.possuiPortabilidade) {
          const cpf = payload.cpf
          const operadorasComIntegracao = {
            'SULAMÉRICA SAÚDE': 'sulamerica',
            'BLUE': 'blue',
            'AMIL SAÚDE SA': 'amil'
          }
          const alvoBusca = operadorasComIntegracao[operadora]
          const bloqueioList = await listaAtencaoService.verificarBloqueiosListaAtencao({ cpf, operadora: alvoBusca });
          const bloqueioEncontrado = operadora === 'SULAMÉRICA SAÚDE' ? find(bloqueioList, { origem: 'sulamerica' }) : operadora === 'BLUE' ? find(bloqueioList, { origem: 'blue' }) : find(bloqueioList, { origem: 'amil' });
          if (bloqueioEncontrado) throw new Error('Cliente bloqueado na operadora');
        }
        this.$emit("saveData", { dependente: payload, index: this.dependente.index });
        this.close();
      } catch (error) {
        this.loading = false;
        if (error.message === 'Cliente bloqueado na operadora') {
          this.$root.$snackBar.open({
            color: 'error',
            message: 'Não é possível seguir com a operadora selecionada, verifique com seu gestor comercial.',
          });            
        }
      }
    },
    async getIdade(value) {
      this.form.dataNascimento = value ? moment(value, "DD/MM/YYYY").format("YYYY-MM-DD") : moment(this.dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD");
      this.idade = filters.getAge(new Date(this.form.dataNascimento));
      if (this.idade) {
        const newLetForDate = this.form.dataNascimento;
        const dateArray = newLetForDate.split("-");
        const dateYear = dateArray[0];
        this.anoNascimento = Number(dateYear);
        await this.buscaRegra();
        this.form.parentesco = null;
        this.estadosCivil = null;
        this.filtroGrauParentesco();
      }
    },
    successCpf(data) {
      if (data && (data !== 'naoConsultaSerasa')) {
        this.loadingMountedCpf = false;
        this.form.dataNascimento = data.dataNascimento;
        this.dependente.dependente.dataNascimento = data.dataNascimento;
        this.form.nomeMae = data.nomeMae;
        this.form.nome = data.nome;
        this.form.sexo = data.sexo;
        this.setdataNascimento();
      }
    },
    setdataNascimento() {
      this.dataNascimento = moment(this.dependente.dependente.dataNascimento).format("DD/MM/YYYY");
    },
    setGrauParentesco(data) {
      this.parentescos = data.map(({ grauParentesco, ...resto }) => ({ ...resto, parentesco: grauParentesco }));
      this.filtroGrauParentesco();
    },
    filtroGrauParentesco() {
      let grausParentesco = this.parentescos.filter((d) => this.idade >= d.idadeMinima && this.idade <= d.idadeMaxima);
      const idadeTitular = filters.getAge(new Date(this.propostaState.titular.dataNascimento));
      if (this.propostaState.titular.entidade === "UBES" && idadeTitular >= 18) {
        grausParentesco = grausParentesco.filter((d) => d.parentesco !== "MÃE" && d.parentesco !== "PAI" && d.parentesco !== "IRMÃO");
      }
      this.parentescosFiltrado = this.parentescos.length > 0 ? grausParentesco : [];
    },
    selectedGrauParentesco(event) {
      this.form.parentesco = event.parentesco;
      this.estadosCivis = event.estadoCivil;
      if (this.form.estadoCivil) {
        if (this.estadosCivis.filter((d) => d.nome === this.form.estadoCivil).length === 0) this.form.estadoCivil = null;
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit("input", false);
    },
    async validarDados() {
      const isValid = await this.$refs.form.validate();
      return isValid;
    },
    // task 156514
    validacaoCPFDependentes(cpf, idade) {
      if (this.verificarCPFTitular(cpf)) {
        if (!this.verificaObrigatoriedadeCPFPorOperadora()) {
          return idade > 7 ? "required|cpf" : "cpf";
        }
        return "required|cpf";
      }
      return "required|cpf|cpf_beneficiario";
    },
    verificaObrigatoriedadeCPFPorOperadora() {
      return ["CCG", "UNIMED BH", "UNIMED FESP", "SMILE SAÚDE"].includes(this.propostaState.plano.operadora.toUpperCase());
    },
    verificarCPFTitular(cpf) {
      if (cpf && cpf.length === 14) {
        cpf = filters.removeSymbols(cpf);
        if (cpf === this.propostaState.titular.cpf) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-hint {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 12px;
}
</style>
